import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from "gatsby";
import Image from '../UI/Image/Image';
import TeaserTitle from '../UI/Teaser/TeaserTitle';
import ScrollReveal from '../UI/ScrollReveal/ScrollReveal';
import styles from './WorkCard.module.scss';

const Wrapper =  React.forwardRef(({ heading, children, isPrimary }, ref) => ( !heading ? children
    : 
    <div className={ isPrimary ? styles.wrapperPrimary : styles.wrapper } ref={ref}>
        <h2 className={styles.wrapperTitle}>
            <span className={styles.wrapperTitleInner}>{ heading }</span>
        </h2>
        {children}
    </div>
));

const WorkCard = React.forwardRef( ( props, ref ) => {
    const [ isHovered, setHover ] = useState( false );
    const className = classnames(props.className, {
        [styles.primary]: props.isPrimary,
        [styles.fullwidth]: props.isFullWidth,
        [styles.default]: !props.isPrimary && !props.isFullWidth,
        [styles.hover]: isHovered
    });
    const TitleTag = props.titleTag;
    
    return (
        <Wrapper heading={props.heading} isPrimary={props.isPrimary}>
            <article className={className} onMouseEnter={ e => setHover( true ) } onMouseLeave={ e => setHover( false ) } onTouchStart={ e => setHover( true ) } onTouchEnd={ e => setHover( false ) } ref={ref}>
                <Link className={styles.inner} to={props.slug}>
                    <div className={styles.body}>
                        <header className={styles.header}>
                            
                            <ScrollReveal animation="blockWipe" delay={0.5}>
                                <TeaserTitle>
                                    { props.subtitle }
                                </TeaserTitle>
                            </ScrollReveal>

                            
                            <ScrollReveal animation="slideUp" delay={0.75}>
                                <TitleTag className={styles.title}>
                                    {props.title.split('\n').map((item, key) => {
                                        return <React.Fragment key={key}>{item}<br/></React.Fragment>
                                    })}
                                </TitleTag>
                            </ScrollReveal>
                            
                        </header>
                        
                        <ScrollReveal animation="slideUp" delay={1.15}>
                            <p className={styles.description}>
                                { props.description }
                            </p>
                        </ScrollReveal>
                        
                    </div>

                    <Image className={styles.img} fluid={props.img.childImageSharp.fluid} maxWidth={props.imgMaxWidth} style={{ maxWidth: 'none' }} loading={props.isPrimary ? 'eager' : 'lazy'} />
                </Link>
            </article>
        </Wrapper>
    );
});


WorkCard.defaultProps = {
    titleTag: 'h2'
};

WorkCard.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    img: PropTypes.object.isRequired
};

export default WorkCard;