import React from 'react';
import { Link } from "gatsby";
import styles from './Button.module.scss';

const Button = React.forwardRef( ( { className, children, ...otherProps }, ref ) => {
    className = [ styles.button, className ].join(' ');

    return otherProps.to ? (
        <Link className={className} {...otherProps} ref={ref}>
            {children}
        </Link>
    ) : (
        <a className={className} {...otherProps} target="_blank" rel="noopener noreferrer" ref={ref}>
            {children}
        </a>
    );
});

export default Button;