import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import WorkCard from "../WorkCard/WorkCard";
import styles from './WorkPrevNext.module.scss';

const WorkPrevNext = forwardRef(({ prev, next }, ref) => {
    return (
        <aside className={styles.navigation} ref={ref}>
            { prev && 
                <WorkCard
                    heading="Previous"
                    title={prev.title} 
                    titleTag="h3"
                    subtitle={prev.subtitle} 
                    description={prev.description} 
                    slug={prev.fields.slug} 
                    img={prev.thumbSrc}
                    imgMaxWidth={ prev && next ? 960 : 1920 }
                    isPrimary={!next}
                    isFullWidth={true}
                    />
            }
            
            { next && 
                <WorkCard
                    heading="Next"
                    title={next.title} 
                    titleTag="h3"
                    subtitle={next.subtitle} 
                    description={next.description} 
                    slug={next.fields.slug} 
                    img={next.thumbSrc}
                    imgMaxWidth={ prev && next ? 960 : 1920 }
                    isPrimary={!prev}
                    isFullWidth={true}
                    />
            }
        </aside>
    );
});

WorkPrevNext.propTypes = {
    
};

export default WorkPrevNext;