class UiIntersectionObserver {
    constructor() {
        this.observers = [];
        this.elements = [];
        this.data = [];
        this.intersecting = {};

        this.handleIntersect = this.handleIntersect.bind( this );
    }

    init( elements, data, callback ){
        this.elements = this.elements.concat( elements );
        this.callback = callback;
        this.data = data;

        const thresholds = this.generateThresholds();
        const rootMargins = [
            '-90% 0px -5% 0px',
            '-47.5% 0px -47.5% 0px',
            '-5% 0px -90% 0px'
        ];

        rootMargins.forEach( ( rootMargin, index ) => {
            const observer = new IntersectionObserver( this.handleIntersect.bind( null, index ), {
                root: null,
                rootMargin,
                threshold: thresholds
            });

            this.intersecting[ index ] = [];

            elements.forEach( element => ( observer.observe( element ) )); 
            this.observers.push( observer );
        }); 
    }

    generateThresholds(){
        const thresholds = [];
        let index = 0;
    
        while ( index < 1 ) {
            thresholds.push( index );
            index += 0.01;
        }

        return thresholds;
    }

    refresh(){
        this.observers.forEach( observer => (
            this.elements.forEach( element => {
                observer.unobserve( element );
                observer.observe( element );
            })
        ));
    }

    handleIntersect( observerIndex, entries ) {
        entries.forEach( (entry) => {
            const isIntersecting = entry.isIntersecting;
            const sectionIndex = this.elements.findIndex( element => element === entry.target );

            if ( isIntersecting ) {
                if( !this.intersecting[ observerIndex ].includes( sectionIndex ) ) {
                    this.intersecting[ observerIndex ].push( sectionIndex );
                }
            } else {
                this.intersecting[ observerIndex ] = this.intersecting[ observerIndex ].filter( index => index !== sectionIndex );
            }
        });

        Object.keys( this.intersecting ).forEach( key => {
            const item = Math.max.apply(null, this.intersecting[ key ] );
            this.chooseTheme( parseInt(key), item );
        });
    }

    chooseTheme( observerIndex, sectionIndex ) {
        let key;
        const isDark = this.data[ sectionIndex ];
        const color = isDark ? '#fff' : '#000';
        
        switch ( observerIndex ) {
            case 0:
                key = 'bottom';
                break;
            case 1:
                key = 'middle';
                break;
            case 2:
                key = 'top';
                break;
            default:
                key = null;
                console.error( 'observerIndex not found' );
                break;
        }

        this.callback( key, color, sectionIndex );
    }

    destroy() {
        this.observers.forEach( observer => observer.disconnect() );
    }
}

export default UiIntersectionObserver;