import React from "react";
import styles from './Video.module.scss';

const Video = ({ src, extension }) => (
  <div className={styles.wrapper}>
    <video className={styles.video} controls loop muted>
      <source src={src + '#t=0.1'} type={`video/${extension}`}/>
    </video>
  </div>
);

export default React.memo( Video );
