import React from 'react';
import classnames from 'classnames';
import { useInView } from 'react-intersection-observer';
import styles from './ScrollReveal.module.scss';

const ScrollReveal = props => {
    const childElement = React.Children.only(props.children);

    const [ref, inView] = useInView({
        threshold: 0.2,
        triggerOnce: true
    });

    const classname = classnames( 
        styles[ props.animation ],
        {
            [ styles.isInView ]: inView
        }
    );

    const child = React.cloneElement(
        childElement, 
        { 
            className: classnames(childElement.props.className, classname),
            ref,
            style: { 
                transitionDelay: `${props.delay}s`,
                animationDelay: `${props.delay}s`
            }
        }
    );

    return props.className ? (
        <props.tag className={props.className}>
            { child }
        </props.tag>
    ) : child
};

ScrollReveal.defaultProps = {
    animation: '',
    delay: 0,
    tag: 'div'
};

export default React.memo( ScrollReveal );