import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './Section.module.scss';

const Section = React.forwardRef((props, ref) => {
    const className = props.className || classnames({
        [ styles.section ]: !props.isDark,
        [ styles.sectionDark ]: props.isDark,
    });

    return (
        <div className={ className } ref={ref}>
            {props.children}
        </div>
    )
});

Section.propTypes = {
    className: PropTypes.string
};

export default Section;