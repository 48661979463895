import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styles from './TeaserTitle.module.scss';

const TeaserTitle = forwardRef(( { className, children, ...otherProps }, ref ) => {
    return (
        <p className={ [ styles.title, className ].join(' ') } {...otherProps} ref={ref}>
            <span>{ children }</span>
        </p>
    );
});

TeaserTitle.propTypes = {
    children: PropTypes.node.isRequired
};

export default TeaserTitle;