import React, { useEffect, useRef, useState, forwardRef } from 'react';
import Image from "../UI/Image/Image";
import styles from './DeviceSimulator.module.scss';

const Device = forwardRef(({ 
    src, 
    play, 
    isMobile, 
    onLoad, 
    onMouseOver, 
    onMouseOut, 
    maxWidth
}, ref ) => {
    const containerRef = useRef(null);
    const innerRef = useRef(null);
    const [ isLoaded, setLoaded ] = useState( false );
    const [ position, setPosition ] = useState( 0 );
    const speed = 15;
    const transitionStyles = { 
        transform: `translateY( -${position}% )`,
        transition: `transform ${play ? speed : 2}s cubic-bezier(.1,0,.7,1)`
    };

    function handleLoad(){
        setLoaded(true);
        onLoad();
    }

    useEffect(() => {
        if( !isLoaded ) return;

        // let timer;

        if( play ) {
            const rect = containerRef.current.getBoundingClientRect();
            const scroll = (rect.height / innerRef.current.clientHeight ) * 100;
            
            setPosition( 100 - scroll );
            // timer = setInterval(() => {
            //     let increment = position + rect.height;
            //     let maxIncrement = innerRef.current.clientHeight - rect.height;

            //     if( increment < maxIncrement ) {
            //         setPosition( increment )
            //     } else {
            //         setPosition( maxIncrement )
            //     }
            // }, 1500);
        } else {
            setPosition( 0 );
            // clearInterval(timer)
        }

        // return () => {
            // clearInterval(timer)
        // }
    }, [isLoaded, play, position]);    

    return (
        <div 
            className={ isMobile ? styles.deviceMobile : styles.device } 
            ref={containerRef} 
            onMouseOver={ e => onMouseOver(true)} 
            onMouseOut={ e => onMouseOut(false)}
            >
            <div className={ styles.deviceInner } ref={innerRef} style={transitionStyles}>
                <Image 
                    className={ styles.img } 
                    maxWidth={ maxWidth ? maxWidth : ( isMobile ? '20vw' : 1200 ) }
                    onLoad = { handleLoad }
                    fluid={src.childImageSharp.fluid}
                />
            </div>
        </div>
    )
});

const DeviceSimulator = ({ desktop, mobile }) => {
    const [ isLoadedCount, setLoaded ] = useState( 0 );
    const [ isPlaying, setPlaying ] = useState( false );
    
    function handleLoad(){
        setLoaded( count => count + 1 );
    }

    function handleMouseOver(){
        if( isLoadedCount === 2 ) {
            setPlaying(true);
        }
    }

    function handleMouseOut(){
        setPlaying(false);
    }

    return (
        <div className={ styles.container }>
            <div className={styles.desktop}>
                <Device 
                    {...desktop} 
                    play={ isPlaying }
                    onLoad={ handleLoad }
                    onMouseOver={ handleMouseOver } 
                    onMouseOut={ handleMouseOut }/>
            </div>
            <div className={styles.mobile}>
                <Device 
                    {...mobile} 
                    play={ isPlaying }
                    onLoad={ handleLoad } 
                    onMouseOver={ handleMouseOver } 
                    onMouseOut={ handleMouseOut }
                    isMobile/>
            </div>
        </div>
    )
}

DeviceSimulator.propTypes = {
    
};

export default React.memo( DeviceSimulator );