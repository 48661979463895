import React from 'react';
import PropTypes from 'prop-types';
import Image from "../UI/Image/Image";

import styles from './Gallery.module.scss';

const Gallery = ({ imgs, isFullWidth, isColumned }) => {
    return (
        <div className={ isFullWidth || !isColumned ? styles.galleryWrapperFull : styles.galleryWrapper }>

            { imgs.length > 1 ?                            
                <div className={ isColumned ? styles.galleryColumned : styles.gallery }>
                    { imgs.map( ( img, index ) => (
                        <div className={ styles.galleryItem } key={index}>
                            <Image className={ styles.galleryImg } fluid={ img.src.childImageSharp.fluid } alt={ img.alt } maxWidth={img.isMobile ? 375 : 800}/>
                        </div>
                    ))}
                </div>

                :
                
                <div className={ styles.galleryItemSingle }>
                    <Image className={ styles.galleryImg } fluid={ imgs[0].src.childImageSharp.fluid } alt={ imgs[0].alt } maxWidth={!isFullWidth ? 1600 : null}/>
                </div>
            }
        </div>
    );
};

Gallery.propTypes = {
    
};

export default Gallery;