import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Img from "gatsby-image";
import Section from "../Section/Section";
import ScrollReveal from '../UI/ScrollReveal/ScrollReveal';
import TeaserTitle from "../UI/Teaser/TeaserTitle";
import styles from './Cover.module.scss';

const Cover = forwardRef(({ 
    title, 
    subtitle, 
    imgSrc,
    description,
    metadata,
    thumbSrc
}, ref) => {
    return (
        <Section className={styles.cover} ref={ref}>
            <div className={styles.coverBody}>
                
                <ScrollReveal animation="blockWipe" delay={0.5}>
                    <TeaserTitle>
                        { subtitle }
                    </TeaserTitle>
                </ScrollReveal>
            
            
                <ScrollReveal animation="slideUp" delay={0.75}>
                    <h1 className={styles.coverTitle}>
                        {title.split('\n').map((item, key) => {
                            return <React.Fragment key={key}>{item}<br/></React.Fragment>
                        })}
                    </h1>
                </ScrollReveal>
            
            
                <ScrollReveal animation="slideUp" delay={1.15}>
                    <p className={styles.coverDesc}>
                        { description }
                    </p>
                </ScrollReveal>
                
            </div>

            <dl className={styles.coverMeta}>
                <dt>Client:</dt>
                <dd>{ metadata.client }</dd>
                <dt>Roles:</dt> 
                <dd>{ metadata.roles.join(', ') }</dd>
                <dt>Status:</dt> 
                <dd>{ metadata.status }</dd>
            </dl>

            { imgSrc && 
                <div className={styles.coverImgWrapper}>
                    { imgSrc.extension === 'svg' ? 
                        <img className={styles.coverImgSvg} src={imgSrc.publicURL} alt="" />
                    :
                        <Img className={styles.coverImg} fluid={imgSrc.childImageSharp.fluid} imgStyle={{ objectPosition: '0% 0%'}} loading="eager" />
                    }
                </div>
            }

            <div className={styles.coverBg}>
                <Img className={styles.coverBg} fluid={thumbSrc.childImageSharp.fluid} loading="eager" />
            </div>
        </Section>
    );
});

Cover.propTypes = {
    
};

export default Cover;