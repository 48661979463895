import React, { Component } from 'react';
import { graphql } from "gatsby";
import withApp, { THEMES } from "../../context/withApp";
import Page from "../../components/Page/Page";
import DeviceSimulator from "../../components/DeviceSimulator/DeviceSimulator";
import SEO from "../../components/SEO/SEO";
import Cover from '../../components/Cover/Cover';
import Section from "../../components/Section/Section";
import Button from '../../components/UI/Button/Button';
import Video from '../../components/UI/Video/Video';
import Html from '../../components/UI/Html';
import ScrollReveal from '../../components/UI/ScrollReveal/ScrollReveal';
import WorkPrevNext from '../../components/WorkPrevNext/WorkPrevNext';

import UiIntersectionObserver from '../../utils/uiIntersectionObserver';
import styles from './WorkPage.module.scss';
import Gallery from '../../components/Gallery/Gallery';


class WorkPage extends Component {
    constructor(props) {
        super(props);

        this.sectionRefs = [ 
            React.createRef(), // cover
            React.createRef() // footer
        ];

        this.props.data.current.sections.forEach( item => {
            this.sectionRefs.push( React.createRef() );
        });

        this.uiIntersectionObserver = new UiIntersectionObserver();
        this.handleIntersect = this.handleIntersect.bind( this );
    }

    componentDidMount(){
        const { data } = this.props;
        const sections = this.sectionRefs.map( ref => ref.current );
        const sectionData = [
            false, // cover
            ...data.current.sections.map( item => item.isDark ),
            true // footer
        ];

        this.uiIntersectionObserver.init( sections, sectionData, this.handleIntersect );  
    }

    componentDidUpdate(prevProps){
        const { page, app } = this.props;
        const { isPanelOpen } = app;
        const hasEntered = ( page.transitionStatus !== prevProps.page.transitionStatus ) && page.transitionStatus === 'entered';
        const wasPanelOpen = ( prevProps.app.isPanelOpen !== isPanelOpen ) && !isPanelOpen;

        if( wasPanelOpen || hasEntered ) {
            this.uiIntersectionObserver.refresh();
        }
    }

    componentWillUnmount(){
        this.props.app.set({ 
            externalLink: null
        });

        this.uiIntersectionObserver.destroy();
    }

    handleIntersect( key, color, sectionIndex ){
        const { page, app, data } = this.props;
        const isTransitioning = page.transitionStatus === 'entering' || page.transitionStatus === 'exiting';

        if( !app.isPanelOpen && !isTransitioning ) {
            app.setUiTheme( key, color, 150 );
        }

        if ( data.current.href ) {
            app.set({ 
                externalLink: sectionIndex > 0 ? data.current.href : null
            });
        }
    }
    
    render() {
        const { page, data } = this.props;
        const { 
            title, 
            subtitle, 
            description,
            href,
            coverSrc,
            thumbSrc,
            metadata,
            sections
        } = data.current;

        return (
            <Page { ...page } theme={ THEMES.light }>
                <SEO title={`Work: ${ title }`} description={description} image={thumbSrc.childImageSharp.fluid.src}/>

                <article className={styles.page}>
                    <Cover 
                        title={title}
                        subtitle={subtitle}
                        description={description}
                        metadata={metadata}
                        imgSrc={coverSrc} 
                        thumbSrc={thumbSrc}
                        ref={this.sectionRefs[0]}/>

                    { sections.map( ( section, index ) => {
                        return (
                            <Section isDark={ section.isDark } ref={ this.sectionRefs[index + 1] } key={index}>
                                { section.title && 
                                    <ScrollReveal className={styles.contentHeading} tag="h2" animation="blockWipe" delay={0}>
                                        <span>
                                            <span>{ section.title }</span>
                                        </span>
                                    </ScrollReveal>
                                }

                                { section.content &&
                                    <ScrollReveal animation="slideUp">
                                        <div className={styles.body}>
                                            <Html className={ section.isContentColumned ? styles.contentColumned : styles.content } html={section.content}/>
                                        </div>
                                    </ScrollReveal>
                                }

                                { section.videoSrc && 
                                    <div className={styles.videoWrapper}>
                                        <Video src={section.videoSrc.publicURL} extension={section.videoSrc.extension}/>
                                    </div>
                                }

                                { section.simulator &&
                                    <DeviceSimulator { ...section.simulator }/>
                                }

                                { section.imgs && section.imgs.length &&
                                    <Gallery
                                        imgs={section.imgs}
                                        isFullWidth={section.isGalleryFullWidth}
                                        isColumned={section.isGalleryColumned}
                                        />
                                }
                                
                            </Section>
                        )
                    }) }
                </article>
                
                <WorkPrevNext prev={data.prev} next={data.next} ref={ this.sectionRefs[ this.sectionRefs.length - 1 ] }/>
            </Page>
        )
    }
}

export const query = graphql`
    query($slug: String!, $prev: String!, $next: String!) {
        current: workJson(fields: { slug: { eq: $slug } }) {
            title
            subtitle
            description
            href
            coverSrc {
                childImageSharp {
                    fluid(maxWidth: 1600, maxHeight: 870, toFormat: JPG, quality: 90, cropFocus: NORTHWEST, srcSetBreakpoints: [ 400, 800, 1200, 1600 ]) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                }
                extension
                publicURL
            }
            thumbSrc {
                childImageSharp {
                  fluid(maxWidth: 1920, toFormat: JPG, quality: 90, grayscale: true) {
                    ...GatsbyImageSharpFluid
                  }
                }
            }
            metadata {
                client
                roles
                status
            }
            sections {
                title
                content
                isContentColumned
                isGalleryColumned
                isGalleryFullWidth
                isDark
                videoSrc {
                    extension
                    publicURL
                },
                simulator {
                    desktop {
                        src {
                            childImageSharp {
                                fluid(maxWidth: 1600, toFormat: JPG, quality: 80, srcSetBreakpoints: [ 400, 800, 1200, 1600, 2400 ]) {
                                  ...GatsbyImageSharpFluid_noBase64
                                }
                            }
                        }
                        alt
                    }
                    mobile {
                        src {
                            childImageSharp {
                                fluid(maxWidth: 1600, toFormat: JPG, quality: 80, srcSetBreakpoints: [ 400, 800, 1200, 1600, 2400 ]) {
                                  ...GatsbyImageSharpFluid_noBase64
                                }
                            }
                        }
                        alt
                    }
                }
                imgs {
                    src {
                        childImageSharp {
                            fluid(maxWidth: 1600, toFormat: JPG, quality: 80, srcSetBreakpoints: [ 400, 800, 1200, 1600, 2400 ]) {
                              ...GatsbyImageSharpFluid_noBase64
                            }
                        }
                    }
                    alt
                    isMobile
                }
    		}
        }
        prev: workJson(fields: { slug: { eq: $prev } }) {
            title
            subtitle
            description
            thumbSrc {
                childImageSharp {
                  fluid(maxWidth: 1920, toFormat: JPG, quality: 50, grayscale: true, srcSetBreakpoints: [ 400, 800, 1600, 2400 ]) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
            }
            fields {
                slug
            }
        }
        next: workJson(fields: { slug: { eq: $next } }) {
            title
            subtitle
            description
            thumbSrc {
                childImageSharp {
                  fluid(maxWidth: 1920, toFormat: JPG, quality: 50, grayscale: true, srcSetBreakpoints: [ 400, 800, 1600, 2400 ]) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
            }
            fields {
                slug
            }
        }
    }
`;

export default withApp( WorkPage );